import {
  RECORD_CONSISTENCY_OPTIONS,
  RECORD_STATUS_OPTIONS,
  RECORD_STATUS_TEXT,
  REQUIRED_RULE,
} from 'utils/constants';
import { RECORD_STATUS, RECORD_STEPS, SPINNER_COLORS } from 'utils/enums';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { addWorkDays, classNames, formatDate, formatNumber } from 'utils/functions';
import { showErrorToast, showSuccessToast } from 'components/Toast';
import { useNavigate, useParams } from 'react-router-dom';

import BaseInput from 'components/Inputs/BaseInput';
import Card from 'components/Card';
import FileUploadComponent from 'components/FileUpload';
import GeneralApi from 'utils/generalApi';
import { IFile } from 'components/FileUpload/interfaces';
import LoadingSpinner from 'components/LoadingSpinner';
import OutlineButton from 'components/OutlineButton';
import PrivateRoute from 'components/Navigation/ProtectedRoute';
import SolidButton from 'components/SolidButton';
import StepTitle from 'components/StepTitle';
import { addDays } from 'date-fns';
import { useAuth } from 'contexts/Auth';
import { useForm } from 'react-hook-form';
import useOnScreen from 'hooks/useOnScreen';

import {
  TrashIcon,
} from '@heroicons/react/outline';

export default function UpdateRecordPage() {
  const {
    register,
    watch,
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<any>();
  const { code } = useParams();
  const history = useNavigate();
  const auth = useAuth();
  const statusRef = useRef<any>();

  const isVisible = useOnScreen(statusRef);

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [loading, setLoading] = useState(false);
  const [initialRecord, setInitialRecord] = useState<any>();
  const [updateLoading, setUpdateLoading] = useState(false);

  const [files, setFiles] = useState<Array<IFile>>([]);

  const [editingStep, setEditingStep] = useState<RECORD_STEPS | undefined>();

  const [diferencia, setDiferencia] = useState('');

  //const [sumillasList, setSumillasList] = useState([]); // Lista de sumillas (JSON)
  const [sumilla, setSumilla] = useState({ titulo: '', fecha: '' });
  const [sumillasList, setSumillasList] = useState<{ titulo: string; fecha: string; }[]>([]); 

  const [sumilla2, setSumilla2] = useState({ titulo: '', fecha: '' });
  const [sumillasList2, setSumillasList2] = useState<{ titulo: string; fecha: string; }[]>([]); 

  const status = watch('status');
  const dailySalary = watch('daily_salary');
  const emittedAt = watch('emitted_at');
  const dmStart = watch('dm_start_at');
  const dmEnd = watch('dm_end_at');
  const resolvedReceivedAt = watch('resolved_received_at');
  const chargedPayAt = watch('charged_pay_at');
  const chargedAt = watch('charged_at');
  const refundAt = watch('refund_at');

  const essaludAmount = watch('charged_essalud_amount');
  const loanAmount = watch('loan_cr_total');
  const dailyAmount = watch('loan_cr_amount');
  const observationAt = watch('observation_at');

  const daysDiff = useMemo(() => {
    const start = new Date(dmStart);
    const end = new Date(dmEnd);
    const diff = end.getTime() - start.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
    return days;
  }, [dmStart, dmEnd]);

  const saveData = async () => {
    if (!(await trigger())) return showErrorToast('Complete todos los campos requeridos');
    setUpdateLoading(true);
    const data = getValues();
    const code = `${data.doc_number}-${formatDate(data.dm_start_at, 'ddMMyyyy')}${formatDate(
      data.dm_end_at,
      'ddMMyyyy'
    )}`;

    data.sumilla = sumillasList; // Agregar sumillasList directamente al objeto data
    data.sumilla2 = sumillasList2; // Agregar sumillasList directamente al objeto data
    let result: any = await generalApi.put('/records', {
      record: initialRecord?._id,
      code,
      amount: daysDiff && dailySalary ? daysDiff * dailySalary : undefined,
      dm_days: daysDiff,
      files,
      ...data,
    });
    if (!result.success) {
      setUpdateLoading(false);
      return showErrorToast(result.message);
    }
    showSuccessToast('Cambios guardados');
    setTimeout(function () {
      setUpdateLoading(false);
      history(`/record/${code}`);
    }, 2000);
  };

  const saveFiles = async (fileList: Array<IFile>) => {
    let result: any = await generalApi.put('/records', {
      record: initialRecord?._id,
      files: fileList,
    });
    if (!result.success) return showErrorToast(result.message);
    showSuccessToast('Archivos del expediente actualizados');
  };

  const buildStepTitle = (title: string, step: RECORD_STEPS) => (
    <StepTitle
      title={title}
      loading={updateLoading}
      step={step}
      currentStep={editingStep}
      setCurrentStep={setEditingStep}
      //onSubmit={saveData}
    />
  );

  const buildBaseInput = (
    step: string | undefined,
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    // if (step && step !== editingStep)
    //   return (
    //     <div>
    //       <p className="font-bold">{label}</p>
    //       <p>
    //         {dateFields.includes(name)
    //           ? formatDate(initialRecord?.[name])
    //           : initialRecord?.[name] || '-'}
    //       </p>
    //     </div>
    //   );
    return (
      <BaseInput
        label={label}
        placeholder={`Ingrese su ${label}`}
        type={type}
        name={name}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        control={control}
        rules={rules}
        {...props}
      />
    );
  };

    // Función para manejar el input de sumilla
    const handleChange = (e:any) => {
      setSumilla({
        ...sumilla,
        [e.target.name]: e.target.value
      });
    };
    const handleChange2 = (e:any) => {
      setSumilla2({
        ...sumilla2,
        [e.target.name]: e.target.value
      });
    };
  
    // Función para agregar la sumilla a la lista
    const agregarSumilla = () => {

      if (sumilla.titulo) {

        const fechaActual = new Date();
        const fechaFormateada = `${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, '0')}-${String(fechaActual.getDate()).padStart(2, '0')} ${String(fechaActual.getHours()).padStart(2, '0')}:${String(fechaActual.getMinutes()).padStart(2, '0')}`;

        sumilla.fecha = fechaFormateada;
        setSumillasList([...sumillasList, sumilla]);
        setSumilla({ titulo: '', fecha: '' }); // Limpiar el input
      } else {
        alert("Ambos campos son requeridos");
      }
    };

    const agregarSumilla2 = () => {

      if (sumilla2.titulo) {

        const fechaActual = new Date();
        const fechaFormateada = `${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, '0')}-${String(fechaActual.getDate()).padStart(2, '0')} ${String(fechaActual.getHours()).padStart(2, '0')}:${String(fechaActual.getMinutes()).padStart(2, '0')}`;

        sumilla2.fecha = fechaFormateada;
        setSumillasList2([...sumillasList2, sumilla2]);
        setSumilla2({ titulo: '', fecha: '' }); // Limpiar el input
      } else {
        alert("Ambos campos son requeridos");
      }
    };

     // Función para eliminar una sumilla de la lista
    const eliminarSumilla = (index: number) => {
      setSumillasList((prevList) => prevList.filter((_, i) => i !== index)); // Filtrar la lista para eliminar el item
    };
    const eliminarSumilla2 = (index: number) => {
      setSumillasList2((prevList) => prevList.filter((_, i) => i !== index)); // Filtrar la lista para eliminar el item
    };


    
  useEffect(() => {
    const getRecord = async () => {
      setLoading(true);
      let result: any = await generalApi.post('/records/details', {
        code,
      });
      setLoading(false);
      if (!result.success) return showErrorToast(result.message);
      const record = result.data;
      setInitialRecord(record);
      [
        'general_status',
        'status',
        'status_detail',
        'doc_type',
        'doc_number',
        'store',
        'name',
        'worker_status',
        'position',
        'phone',
        'email',
        'gender',
        'daily_salary',
        'contingency',
        'dm_type',
        'diagnosis',
        'general_diagnosis',
        'specialty',
        'center',
        'doctor_name',
        'cmp',
        'nitt',
        'citt',
        'comment',
        'loan_nitt',
        'loan_cr_amount',
        'loan_comment',
        'loan_cr_total',
        'resolved_number',
        'resolved_motive',
        'resolved_comment',
        'charged_essalud_amount',
        'charged_difference',
        'charged_number',
        'charged_voucher',
        'charged_comment',
        'refund_amount',
        'refund_nit',
        'refund_number',
        'refund_voucher',
        'refund_comment',
        'discount_amount',
        'discount_comment',
        'not_recovered_amount',
        'not_recovered_comment',
      ].forEach((field) => setValue(field, record[field]));
      if (record.worker_start_at)
        setValue('worker_start_at', formatDate(record.worker_start_at, 'yyyy-MM-dd'));
      if (record.worker_end_at)
        setValue('worker_end_at', formatDate(record.worker_end_at, 'yyyy-MM-dd'));
      setValue('dm_start_at', formatDate(record.dm_start_at, 'yyyy-MM-dd'));
      setValue('dm_end_at', formatDate(record.dm_end_at, 'yyyy-MM-dd'));
      if (record.emitted_at) setValue('emitted_at', formatDate(record.emitted_at, 'yyyy-MM-dd'));
      if (record.exchange_deadline_at || record.emitted_at)
        setValue(
          'exchange_deadline_at',
          formatDate(
            record.exchange_deadline_at ? addWorkDays(new Date(record.emitted_at), 30) : new Date(),
            'yyyy-MM-dd'
          )
        );
      if (record.exchange_at)
        setValue('exchange_at', formatDate(record.exchange_at || new Date(), 'yyyy-MM-dd'));
      if (record.worker_comm_at)
        setValue('worker_comm_at', formatDate(record.worker_comm_at, 'yyyy-MM-dd'));
      if (record.citt_at) setValue('citt_at', formatDate(record.citt_at, 'yyyy-MM-dd'));
      if (record.observation_at)
        setValue('observation_at', formatDate(record.observation_at, 'yyyy-MM-dd'));
      if (record.fix_deadline_at)
        setValue('fix_deadline_at', formatDate(record.fix_deadline_at, 'yyyy-MM-dd'));
      if (record.fix_at) setValue('fix_at', formatDate(record.fix_at, 'yyyy-MM-dd'));

      if (!record.loan_deadline_at) {
        setValue(
          'loan_deadline_at',
          formatDate(addDays(new Date(record.dm_end_at), 180), 'yyyy-MM-dd')
        );
      } else setValue('loan_deadline_at', formatDate(record.loan_deadline_at, 'yyyy-MM-dd'));
      if (record.loan_upe_at) setValue('loan_upe_at', formatDate(record.loan_upe_at, 'yyyy-MM-dd'));
      if (record.loan_observation_upe_at)
        setValue(
          'loan_observation_upe_at',
          formatDate(record.loan_observation_upe_at, 'yyyy-MM-dd')
        );
      if (record.loan_deadline_upe_at)
        setValue('loan_deadline_upe_at', formatDate(record.loan_deadline_upe_at, 'yyyy-MM-dd'));
      if (record.reception_exp_at)
        setValue('reception_exp_at', formatDate(record.reception_exp_at, 'yyyy-MM-dd'));
      if (record.loan_fix_upe_at)
        setValue('loan_fix_upe_at', formatDate(record.loan_fix_upe_at, 'yyyy-MM-dd'));

      if (record.resolved_received_at)
        setValue('resolved_received_at', formatDate(record.resolved_received_at, 'yyyy-MM-dd'));
      if (record.resolved_response_at)
        setValue('resolved_response_at', formatDate(record.resolved_response_at, 'yyyy-MM-dd'));

      if (record.charged_pay_at)
        setValue('charged_pay_at', formatDate(record.charged_pay_at, 'yyyy-MM-dd'));
      if (record.charged_limit_at)
        setValue('charged_limit_at', formatDate(record.charged_limit_at, 'yyyy-MM-dd'));
      if (record.charged_at) setValue('charged_at', formatDate(record.charged_at, 'yyyy-MM-dd'));

      if (record.refund_at) setValue('refund_at', formatDate(record.refund_at, 'yyyy-MM-dd'));
      if (record.refund_payment_at)
        setValue('refund_payment_at', formatDate(record.refund_payment_at, 'yyyy-MM-dd'));
      if (record.refund_collection_at)
        setValue('refund_collection_at', formatDate(record.refund_collection_at, 'yyyy-MM-dd'));

      if (record.discount_at) setValue('discount_at', formatDate(record.discount_at, 'yyyy-MM-dd'));
      if (record.not_recovered_at)
        setValue('not_recovered_at', formatDate(record.not_recovered_at, 'yyyy-MM-dd'));

      setFiles(record.files);
      setSumillasList(record.sumilla||[])
      setSumillasList2(record.sumilla2||[])
    };
    if (code) getRecord();
  }, [code, generalApi, setValue]);

  useEffect(() => {
    if (!initialRecord) return;
  }, [initialRecord]);

  useEffect(() => {
    if (observationAt)
      setValue(
        'fix_deadline_at',
        formatDate(addWorkDays(new Date(observationAt), 30), 'yyyy-MM-dd')
      );
  }, [observationAt, setValue]);

  useEffect(() => {
    if (emittedAt)
      setValue(
        'exchange_deadline_at',
        formatDate(addWorkDays(new Date(emittedAt), 30), 'yyyy-MM-dd')
      );
  }, [emittedAt, setValue]);

  useEffect(() => {
    if (dmEnd)
      setValue('loan_deadline_at', formatDate(addDays(new Date(dmEnd), 180), 'yyyy-MM-dd'));
  }, [dmEnd, setValue]);

  useEffect(() => {
    if (resolvedReceivedAt)
      setValue(
        'resolved_deadline_at',
        formatDate(addWorkDays(new Date(resolvedReceivedAt), 15), 'yyyy-MM-dd')
      );
  }, [resolvedReceivedAt, setValue]);

  useEffect(() => {
    if (chargedPayAt)
      setValue(
        'charged_limit_at',
        formatDate(addWorkDays(new Date(chargedPayAt), 20), 'yyyy-MM-dd')
      );
  }, [chargedPayAt, setValue]);

  useEffect(() => {
    if (chargedAt)
      setValue(
        'refund_deadline_at',
        formatDate(addWorkDays(new Date(chargedAt), 15), 'yyyy-MM-dd')
      );
  }, [chargedAt, setValue]);

  useEffect(() => {
    if (loanAmount && essaludAmount !== undefined) {
      // setValue('charged_difference', loanAmount - essaludAmount);

      const daysDiffTemp = Math.round(loanAmount - essaludAmount);
      if(daysDiffTemp>= 0) {
        setDiferencia('S/ (diferencia) ');
      }else {
        setDiferencia('S/ (saldo a favor) ');
      }

      setValue('charged_difference',  Math.round(loanAmount - essaludAmount));
      setValue('refund_amount', loanAmount - essaludAmount);
    }
  }, [essaludAmount, loanAmount, setValue]);

  useEffect(() => {
    // if (dailyAmount && daysDiff) setValue('loan_cr_total', dailyAmount * daysDiff);
    // const daysDiffTemp = Math.round(dailyAmount * daysDiff);
    // if(daysDiffTemp>= 0) {
    //   setDiferencia('S/ (diferencia) ');
    // }else {
    //   setDiferencia('S/ (saldo a favor) ');
    // }
    if (dailyAmount && daysDiff) setValue('loan_cr_total', Math.round(dailyAmount * daysDiff));
  }, [dailyAmount, daysDiff, setValue]);

  return (
    <PrivateRoute>
      <form
        className={classNames(
          status === RECORD_STATUS.DISCOUNT ? 'pb-52 sm:pb-64' : 'pb-64 sm:pb-72',
          'mx-auto h-full max-w-7xl px-1 pt-8 text-[#1B205A] lg:pt-24 lg:pb-32'
        )}
        onSubmit={handleSubmit(saveData)}
      >
        {loading && (
          <div className="fixed inset-0 z-20 flex items-center justify-center bg-black/20">
            <div className="rounded-lg bg-white px-8 py-4 shadow-lg">
              <LoadingSpinner content="Buscando expediente" color={SPINNER_COLORS.PRIMARY} />
            </div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <h1 className="mb-4 text-xl font-bold text-primary-base sm:text-3xl">
            EXPEDIENTE {code}
          </h1>
          <OutlineButton className="w-auto" onClick={() => history(-1)}>
            Volver
          </OutlineButton>
        </div>
        <div
          ref={statusRef}
          className="mb-8 grid gap-4 border-b border-gray-400 py-12 lg:grid-cols-4 lg:gap-x-8"
        >
          {buildBaseInput(
            undefined,
            'Estado General',
            'select',
            'general_status',
            {},
            {
              options: [
                { value: 'valid', name: 'VIGENTE' },
                { value: 'closed', name: 'CERRADO' },
              ],
            }
          )}
          {buildBaseInput(
            undefined,
            'Status',
            'select',
            'status',
            {},
            {
              options: [
                RECORD_STATUS.NEW,
                RECORD_STATUS.UPE,
                RECORD_STATUS.IN_EXCHANGE,
                RECORD_STATUS.IN_LOAN,
                RECORD_STATUS.RESOLVED,
                RECORD_STATUS.CHARGED,
                RECORD_STATUS.DISCOUNT,
                RECORD_STATUS.NOT_RECOVERED,
                RECORD_STATUS.REACTIVATION,
              ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
              onChange: (value: string) => {
                setValue('status', value);
                setValue(
                  'status_detail',
                  RECORD_STATUS_OPTIONS[value as RECORD_STATUS]?.[0]?.value
                );
              },
            }
          )}
          {status !== RECORD_STATUS.DISCOUNT &&
            buildBaseInput(
              undefined,
              'Detalle de status',
              'select',
              'status_detail',
              {},
              {
                options: RECORD_STATUS_OPTIONS[status as RECORD_STATUS],
              }
            )}
             {buildBaseInput(undefined, 'Fecha de recepción de expediente', 'date', 'reception_exp_at', {}, {})}
          <SolidButton
            className="mx-auto w-auto px-8"
            isLoading={updateLoading}
            isDisabled={editingStep !== undefined}
            onClick={() => saveData()}
          >
            GUARDAR CAMBIOS
          </SolidButton>
        </div>

        <Card className="mb-8" title={buildStepTitle('DATOS DEL COLABORADOR', RECORD_STEPS.WORKER)}>
          <div
            className={classNames(
              editingStep === RECORD_STEPS.WORKER ? 'lg:gap-x-8' : 'lg:gap-x-8',
              'grid gap-4 lg:grid-cols-3'
            )}
          >
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Tipo de documento',
              'select',
              'doc_type',
              {},
              {
                disabled: true,
                options: [{ name: 'DNI' }, { value: 'CE', name: 'C.E.' }],
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Número de documento',
              'text',
              'doc_number',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput(RECORD_STEPS.WORKER, 'Sede', 'text', 'store', {}, {})}
            {buildBaseInput(RECORD_STEPS.WORKER, 'Apellidos y nombres', 'text', 'name', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Estado de trabajador',
              'select',
              'worker_status',
              {},
              {
                options: [{ name: 'ACTIVO' }, { name: 'CESADO' }],
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Fecha de ingreso',
              'date',
              'worker_start_at',
              {},
              {}
            )}
            {buildBaseInput(RECORD_STEPS.WORKER, 'Fecha de cese', 'date', 'worker_end_at', {}, {})}
            {buildBaseInput(RECORD_STEPS.WORKER, 'Cargo', 'text', 'position', {}, {})}
            {buildBaseInput(RECORD_STEPS.WORKER, 'Telefono', 'text', 'phone', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Email',
              'email',
              'email',
              {},
              { textUppercase: false }
            )}
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Sexo',
              'select',
              'gender',
              {},
              {
                options: [{ name: 'MASCULINO' }, { name: 'FEMENINO' }],
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.WORKER,
              'Remuneración diaria aprox',
              'number',
              'daily_salary',
              {},
              {
                prefix: 'S/ ',
              }
            )}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle('DATOS DEL DESCANSO MÉDICO', RECORD_STEPS.MEDICAL_REST)}
        >
          <div
            className={classNames(
              editingStep === RECORD_STEPS.WORKER ? 'lg:gap-x-8' : 'lg:gap-x-8',
              'grid gap-4 lg:grid-cols-3'
            )}
          >
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'Contingencia',
              'select',
              'contingency',
              {},
              {
                options: RECORD_CONSISTENCY_OPTIONS,
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'Tipo D.M',
              'select',
              'dm_type',
              {},
              {
                options: [{ name: 'ESSALUD' }, { name: 'PARTICULAR' }],
              }
            )}
            {buildBaseInput(RECORD_STEPS.MEDICAL_REST, 'Diagnóstico', 'text', 'diagnosis', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'Diagnóstico General',
              'select',
              'general_diagnosis',
              {},
              {
                options: [
                  { name: 'ACCIDENTES' },
                  { name: 'ALTERACIONES DE LA PIEL Y ANEXOS' },
                  { name: 'ALTERACIONES DEL APARATO RESPIRATORIO' },
                  { name: 'ALTERACIONES DEL APARATO DIGESTIVO' },
                  { name: 'ALTERACIONES OTORRINOLARINGOLÓGICAS' },
                  { name: 'ALTERACIONES DENTALES' },
                  { name: 'ALTERACIONES GENITOURINARIAS' },
                  { name: 'ALTERACIONES GINECOLÓGICAS' },
                  { name: 'ALTERACIONES MÚSCULO ESQUELÉTICAS' },
                  { name: 'ALTERACIONES NEUROLÓGICAS' },
                  { name: 'ALTERACIONES PSIQUIÁTRICAS' },
                  { name: 'ALTERACIONES OFTALMOLÓGICAS' },
                  { name: 'ALTERACIONES CARDIOVASCULARES' },
                  { name: 'ALTERACIONES ONCOLÓGICAS' },
                  { name: 'ALTERACIONES INFECCIOSAS (INC. COVID-19 Y SECUELAS)' },
                  { name: 'HOSPITALIZACIÓN' },
                  { name: 'MATERNIDAD PRE Y POST PARTO' },
                  { name: 'OTROS' },
                ],
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'Especialidad',
              'select',
              'specialty',
              {},
              {
                options: [
                  { value: 'CARDIOLOGIA', name: 'CARDIOLOGIA' },
                  { value: 'CIRUGIA', name: 'CIRUGIA' },
                  { value: 'DERMATOLOGIA', name: 'DERMATOLOGIA' },
                  { value: 'ENDOCRINOLOGIA', name: 'ENDOCRINOLOGIA' },
                  { value: 'ESTOMATOLOGIA (DENTAL)', name: 'ESTOMATOLOGIA (DENTAL)' },
                  { value: 'GASTROENTEROLOGIA', name: 'GASTROENTEROLOGIA' },
                  { value: 'GINECOLOGIA', name: 'GINECOLOGIA' },
                  { value: 'HEMATOLOGIA', name: 'HEMATOLOGIA' },
                  {
                    value: 'MEDICINA FISICA Y REHABILITACION',
                    name: 'MEDICINA FISICA Y REHABILITACION',
                  },
                  { value: 'MEDICINA GENERAL', name: 'MEDICINA GENERAL' },
                  { value: 'MEDICINA INTERNA', name: 'MEDICINA INTERNA' },
                  { value: 'NEFROLOGIA', name: 'NEFROLOGIA' },
                  { value: 'NEUMOLOGIA', name: 'NEUMOLOGIA' },
                  { value: 'NEUROLOGIA', name: 'NEUROLOGIA' },
                  { value: 'OBSTETRICIA (MATERNIDAD)', name: 'OBSTETRICIA (MATERNIDAD)' },
                  { value: 'OFTALMOLOGIA', name: 'OFTALMOLOGIA' },
                  { value: 'ONCOLOGIA', name: 'ONCOLOGIA' },
                  { value: 'OTORRINOLARINGOLOGIA', name: 'OTORRINOLARINGOLOGIA' },
                  { value: 'PSIQUIATRIA', name: 'PSIQUIATRIA' },
                  { value: 'TRAUMATOLOGIA', name: 'TRAUMATOLOGIA' },
                  { value: 'UROLOGIA', name: 'UROLOGIA' },
                  { value: 'OTROS', name: 'OTROS' },
                ],
              }
            )}
            {buildBaseInput(RECORD_STEPS.MEDICAL_REST, 'Centro medico', 'text', 'center', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'Nombre del médico',
              'text',
              'doctor_name',
              {},
              {}
            )}
            {buildBaseInput(RECORD_STEPS.MEDICAL_REST, 'C.M.P', 'text', 'cmp', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'Fecha de emisión',
              'date',
              'emitted_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.MEDICAL_REST,
              'F. inicio D.M',
              'date',
              'dm_start_at',
              {},
              {}
            )}
            {buildBaseInput(RECORD_STEPS.MEDICAL_REST, 'F. fin D.M', 'date', 'dm_end_at', {}, {})}
          </div>
          {dmStart && dmEnd && dailySalary && (
            <div className="mt-8 flex justify-around text-center">
              <div>
                <p>Días</p>
                <p className="text-xl font-bold">{daysDiff}</p>
              </div>
              <div>
                <p>Año de Subsidio</p>
                <p className="text-xl font-bold">{formatDate(dmStart, 'yyyy')}</p>
              </div>
              <div>
                <p>Mes de Subsidio</p>
                <p className="text-xl font-bold">{formatDate(dmStart, 'MMMM')}</p>
              </div>
              <div>
                <p>Importe Aproximado de Subsidio</p>
                <p className="text-xl font-bold">S/ {formatNumber(Number((daysDiff * dailySalary).toFixed(2)))}</p>
              </div>
            </div>
          )}
        </Card>

        <Card className="mb-8" title={buildStepTitle('VALIDACIÓN DE D.M', RECORD_STEPS.VALIDATION)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'F. limite de canje',
              'date',
              'exchange_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'F. de gestión de canje',
              'date',
              'exchange_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'F. de comunicación al trabajador',
              'date',
              'worker_comm_at',
              {},
              {}
            )}
            {buildBaseInput(RECORD_STEPS.VALIDATION, 'NIT de canje', 'text', 'nitt', {}, {})}
            {buildBaseInput(RECORD_STEPS.VALIDATION, 'N° de CITT', 'text', 'citt', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'F. de validación CITT',
              'date',
              'citt_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'F. de observación',
              'date',
              'observation_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'F. limite para subsanar',
              'date',
              'fix_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput(RECORD_STEPS.VALIDATION, 'F. de subsanación', 'date', 'fix_at', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.VALIDATION,
              'Comentarios adicionales',
              'text',
              'comment',
              {},
              {
                parentClass: 'col-span-3',
              }
            )}

            {/* Nuevos campos agregados debajo del importe total de subsidio */}
            <div className="lg:col-span-3 mt-4 flex flex-col lg:flex-row items-center">
              <div className="flex-1 mr-8 w-2/3">
                <label htmlFor="titulo" className="block text-sm font-medium text-gray-700">
                  Título de la sumilla
                </label>
                <input
                  type="text"
                  name="titulo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  value={sumilla2.titulo}
                  onChange={handleChange2}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevenir el comportamiento por defecto
                    }
                  }} // Prevenir el envío del formulario
                />
              </div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-md mt-5"
                onClick={agregarSumilla2}
              >
                Agregar Sumilla
              </button>
            </div>

            {sumillasList2.length > 0 && (
              <div className="lg:col-span-3 mt-4 overflow-x-auto"> {/* Añadir overflow-x-auto para manejar el desbordamiento */}
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SUMILLA
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Fecha y hora
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sumillasList2.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.titulo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.fecha} {/* Cambiar esto a item.fecha si estás mostrando la fecha */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <button
                            onClick={(event) => {
                              event.preventDefault(); // Previene el comportamiento por defecto
                              eliminarSumilla2(index); // Llama a la función de eliminar
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <TrashIcon className="h-5 w-5 inline" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle(
            'GESTIÓN DE PRESTACIONES - REEMBOLSO DE SUBSIDIO',
            RECORD_STEPS.IN_LOAN
          )}
        >
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'F. limite de prestaciones',
              'date',
              'loan_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput(RECORD_STEPS.IN_LOAN, 'F. gestion UPE', 'date', 'loan_upe_at', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'NIT de prestaciones',
              'text',
              'loan_nitt',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'Importe diario CR',
              'number',
              'loan_cr_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'Comentarios adicionales',
              'text',
              'loan_comment',
              {},
              {
                parentClass: 'col-span-2',
              }
            )}
            {/* {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'F. de observacion UPE',
              'date',
              'loan_observation_upe_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'F. limite para subsanar UPE',
              'date',
              'loan_deadline_upe_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.IN_LOAN,
              'F. de subsanación UPE',
              'date',
              'loan_fix_upe_at',
              {},
              {}
            )} */}
            {loanAmount && (
              <div className="col-span-3 mt-4 flex flex-col items-center">
                <p>Importe total de subsidio (C.R.)</p>
                <p className="text-xl font-bold">S/ {loanAmount}</p>
              </div>
            )}

            {/* Nuevos campos agregados debajo del importe total de subsidio */}
            <div className="lg:col-span-3 mt-4 flex flex-col lg:flex-row items-center">
              <div className="flex-1 mr-8 w-2/3">
                <label htmlFor="titulo" className="block text-sm font-medium text-gray-700">
                  Título de la sumilla
                </label>
                <input
                  type="text"
                  name="titulo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  value={sumilla.titulo}
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevenir el comportamiento por defecto
                    }
                  }} // Prevenir el envío del formulario
                />
              </div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-md mt-5"
                onClick={agregarSumilla}
              >
                Agregar Sumilla
              </button>
            </div>
    
            {sumillasList.length > 0 && (
              <div className="lg:col-span-3 mt-4 overflow-x-auto"> {/* Añadir overflow-x-auto para manejar el desbordamiento */}
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SUMILLA
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Fecha y hora
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sumillasList.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.titulo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.fecha} {/* Cambiar esto a item.fecha si estás mostrando la fecha */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <button
                            onClick={(event) => {
                              event.preventDefault(); // Previene el comportamiento por defecto
                              eliminarSumilla(index); // Llama a la función de eliminar
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <TrashIcon className="h-5 w-5 inline" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle('GESTIÓN DE PRESTACIONES - RESOLUCIÓN', RECORD_STEPS.RESOLVED)}
        >
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              RECORD_STEPS.RESOLVED,
              'Fecha recepción resolución',
              'date',
              'resolved_received_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.RESOLVED,
              'N° de resolucion',
              'text',
              'resolved_number',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.RESOLVED,
              'Motivo resolucion',
              'text',
              'resolved_motive',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.RESOLVED,
              'F. límite respuesta resolución',
              'date',
              'resolved_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.RESOLVED,
              'F. de respuesta resolución',
              'date',
              'resolved_response_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.RESOLVED,
              'Comentarios adicionales',
              'text',
              'resolved_comment',
              {},
              {}
            )}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle(
            'GESTIÓN DE PRESTACIONES - RECEPCIÓN DE PAGO',
            RECORD_STEPS.CHARGED
          )}
        >
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              RECORD_STEPS.CHARGED,
              'Fecha de pago',
              'date',
              'charged_pay_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.CHARGED,
              'F. límite para cobrar subsidio',
              'date',
              'charged_limit_at',
              {},
              {}
            )}
            {buildBaseInput(RECORD_STEPS.CHARGED, 'Fecha de cobro', 'date', 'charged_at', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.CHARGED,
              'Importe EsSalud',
              'number',
              'charged_essalud_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.CHARGED,
              'Diferencia',
              'number',
              'charged_difference',
              {},
              {
                prefix: diferencia,
                disabled: true,
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.CHARGED,
              'N° expediente',
              'text',
              'charged_number',
              {},
              {}
            )}
            {buildBaseInput(RECORD_STEPS.CHARGED, 'N° voucher', 'text', 'charged_voucher', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.CHARGED,
              'Comentarios adicionales',
              'text',
              'charged_comment',
              {},
              {
                parentClass: 'col-span-2',
              }
            )}
          </div>
        </Card>

        <Card className="mb-8" title={buildStepTitle('REINTEGRO', RECORD_STEPS.PARTIAL_REFUND)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'F. límite solicitud de reintegro',
              'date',
              'refund_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {refundAt !== '' &&
              buildBaseInput(
                RECORD_STEPS.PARTIAL_REFUND,
                'Importe Reintegro',
                'number',
                'refund_amount',
                {},
                {
                  prefix: 'S/ ',
                }
              )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'F. de solicitud reintegro',
              'date',
              'refund_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'Nit solicitud de reintegro',
              'number',
              'refund_nit',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'N° expediente reintegro',
              'text',
              'refund_number',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'F. de pago reintegro',
              'date',
              'refund_payment_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'F. de cobro reintegro',
              'date',
              'refund_collection_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'N° voucher reintegro',
              'text',
              'refund_voucher',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.PARTIAL_REFUND,
              'Comentarios adicionales',
              'text',
              'refund_comment',
              {},
              {}
            )}
          </div>
        </Card>

        <Card className="mb-8" title={buildStepTitle('DESCUENTO', RECORD_STEPS.DISCOUNT)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(RECORD_STEPS.DISCOUNT, 'F. descuento', 'date', 'discount_at', {}, {})}
            {buildBaseInput(
              RECORD_STEPS.DISCOUNT,
              'Importe descontado',
              'number',
              'discount_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.DISCOUNT,
              'Comentarios adicionales',
              'text',
              'discount_comment',
              {},
              {}
            )}
          </div>
        </Card>

        <Card className="mb-8" title={buildStepTitle('NO RECUPERADO', RECORD_STEPS.NOT_RECOVERED)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              RECORD_STEPS.NOT_RECOVERED,
              'F. pase a No recuperado',
              'date',
              'not_recovered_at',
              {},
              {}
            )}
            {buildBaseInput(
              RECORD_STEPS.NOT_RECOVERED,
              'Importe no recuperado',
              'number',
              'not_recovered_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput(
              RECORD_STEPS.NOT_RECOVERED,
              'Comentarios adicionales',
              'text',
              'not_recovered_comment',
              {},
              {}
            )}
          </div>
        </Card>

        <FileUploadComponent
          generalApi={generalApi}
          files={files}
          setFiles={(newFiles) => {
            setFiles(newFiles);
            saveFiles(newFiles);
          }}
        />

        <div className="fixed bottom-0 left-0 right-0 hidden border-t border-gray-400 bg-white py-4">
          <div className="mx-auto max-w-7xl justify-end lg:flex">
            <SolidButton className="px-8 lg:w-auto" isLoading={loading} onClick={() => saveData()}>
              GUARDAR CAMBIOS
            </SolidButton>
          </div>
        </div>

        {!isVisible && (
          <div className="fixed bottom-0 left-0 right-0 border-t border-gray-400 bg-white px-1 py-4 sm:py-8">
            <div className="mx-auto grid max-w-7xl gap-4 lg:grid-cols-4 lg:gap-x-8">
              {buildBaseInput(
                undefined,
                'Estado General',
                'select',
                'general_status',
                REQUIRED_RULE,
                {
                  position: 'top',
                  options: [
                    { value: 'valid', name: 'VIGENTE' },
                    { value: 'closed', name: 'CERRADO' },
                  ],
                }
              )}
              {buildBaseInput(undefined, 'Status', 'select', 'status', REQUIRED_RULE, {
                position: 'top',
                options: [
                  RECORD_STATUS.NEW,
                  RECORD_STATUS.DISCOUNT,
                  RECORD_STATUS.IN_EXCHANGE,
                  RECORD_STATUS.IN_LOAN,
                  RECORD_STATUS.RESOLVED,
                  RECORD_STATUS.CHARGED,
                  RECORD_STATUS.NOT_RECOVERED,
                  RECORD_STATUS.UPE,
                ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
                onChange: (value: string) => {
                  setValue('status', value);
                  setValue(
                    'status_detail',
                    RECORD_STATUS_OPTIONS[value as RECORD_STATUS]?.[0]?.value
                  );
                },
              })}
              {status !== RECORD_STATUS.DISCOUNT &&
                buildBaseInput(
                  undefined,
                  'Detalle de status',
                  'select',
                  'status_detail',
                  REQUIRED_RULE,
                  {
                    position: 'top',
                    options: RECORD_STATUS_OPTIONS[status as RECORD_STATUS],
                  }
                )}
              <SolidButton
                className="mx-auto w-auto px-8"
                isLoading={updateLoading}
                isDisabled={editingStep !== undefined}
                onClick={() => saveData()}
              >
                GUARDAR CAMBIOS
              </SolidButton>
            </div>
          </div>
        )}
      </form>
    </PrivateRoute>
  );
}
