import React, { Fragment } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  CheckIcon,
  TrashIcon,
  PlusCircleIcon,
  ClipboardListIcon,
} from '@heroicons/react/outline';

import OutlineButton from 'components/OutlineButton';

import { ITableFilter, ITableFilterNumber, ITableFilterDate, ITableColumn } from './interfaces';
import { classNames, cleanString } from 'utils/functions';
import { DEFAULT_FILTERS } from 'utils/constants';
import { useEffect } from 'react';
export default function FiltersComponent({
  showFilters,
  appliedFilters,
  searchTimeout,
  columns,
  shownColumns,
  setAppliedFilters,
  setSearchTimeout,
  setShownColumns,
  getData,
}: {
  showFilters?: boolean;
  appliedFilters: Array<ITableFilter>;
  searchTimeout: NodeJS.Timeout | undefined;
  columns: Array<ITableColumn>;
  shownColumns: Array<ITableColumn>;
  setAppliedFilters: (filters: Array<ITableFilter>) => void;
  setSearchTimeout: (timeout: NodeJS.Timeout | undefined) => void;
  setShownColumns: (columns: Array<ITableColumn>) => void;
  getData: (newFilters?: Array<ITableFilter>) => void;
}) {
  const [selectSearch, setSelectSearch] = React.useState('');
  const [filtersSearch, setFiltersSearch] = React.useState('');
  const [columnsSearch, setColumnsSearch] = React.useState('');

  const currentPath = window.location.pathname;

  const updateShownColumns = () => {
    console.log('Esta función se ejecuta manualmente');
    let storedColumns = localStorage.getItem('ShowColumns');
    let counterArray = storedColumns ? JSON.parse(storedColumns) : [];
    let newColumns: Array<ITableColumn> = [...shownColumns]; // Copia inicial

    for (const column of counterArray) {
      if (column.active === true) {
        newColumns = newColumns.filter(
          (item) => item?.dataKey !== (column.key || column.dataKey)
        );
      }
    }

    setShownColumns(
      columns.filter((item) =>
        newColumns.some((obj) => item?.dataKey === (obj.key || obj.dataKey))
      )
    );
  };
  const updateShownColumns2 = () => {
    console.log('Esta función se ejecuta manualmente');
    let storedColumns = localStorage.getItem('ShowColumns2');
    let counterArray = storedColumns ? JSON.parse(storedColumns) : [];
    let newColumns: Array<ITableColumn> = [...shownColumns]; // Copia inicial

    for (const column of counterArray) {
      if (column.active === true) {
        newColumns = newColumns.filter(
          (item) => item?.dataKey !== (column.key || column.dataKey)
        );
      }
    }

    setShownColumns(
      columns.filter((item) =>
        newColumns.some((obj) => item?.dataKey === (obj.key || obj.dataKey))
      )
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentPath === '/records') {
      updateShownColumns();
    } else if (currentPath === '/data') {
      updateShownColumns2();
    }

    return () => {
      // Aquí podrías limpiar cualquier suscripción o temporizador si existiera
    };
  }, [currentPath]); // Dependencia actualizada para que se ejecute en cambios de path

  return (
    <div className="flex flex-col items-center space-y-4 border-t-2 border-accent px-2 py-4 lg:flex-row lg:space-y-0 lg:space-x-2 lg:px-6">
      <Popover.Group className="flex w-full flex-1 flex-col gap-y-2 gap-x-4 lg:flex-row lg:flex-wrap">
        {appliedFilters.map((item) => (
          <Popover key={item.key} className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'ring-2 ring-primary-base' : 'ring-gray-200',
                    'cursor-pointer rounded-lg bg-gray-50 py-2 px-3 text-left outline-none ring-1 transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                  )}
                >
                  {item.title}
                  {['text', 'combo'].includes(item.type) && item.value ? `: ${item.value}` : ''}
                  {item.type === 'number' && (item.value as ITableFilterNumber).from
                    ? `: Desde ${(item.value as ITableFilterNumber).from}`
                    : ''}
                  {item.type === 'number' && (item.value as ITableFilterNumber).to
                    ? `${(item.value as ITableFilterNumber).from ? ' hasta' : ': Hasta'} ${(item.value as ITableFilterNumber).to
                    }`
                    : ''}
                  {item.type === 'date' && (item.value as ITableFilterDate).from
                    ? `: Desde ${(item.value as ITableFilterDate).from}`
                    : ''}
                  {item.type === 'date' && (item.value as ITableFilterDate).to
                    ? `${(item.value as ITableFilterDate).from ? ' hasta' : ': Hasta'}  ${(item.value as ITableFilterDate).to
                    }`
                    : ''}
                  {item.type === 'select' && (item.value as Array<any>).length > 0
                    ? `: ${(item.value as Array<any>).map((obj) => obj.name).join(', ')}`
                    : ''}
                </Popover.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Popover.Panel
                    className={classNames(
                      item.type === 'date' ? 'max-w-sm' : 'max-w-xs',
                      item.type === 'select' ? '' : 'min-w-[120px] lg:w-screen',
                      'absolute left-0 z-10 mt-3 transform'
                    )}
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative flex items-center rounded-lg bg-white p-2">
                        {['text', 'combo'].includes(item.type) && (
                          <input
                            autoFocus
                            type="text"
                            placeholder="Ingresar valor"
                            value={item.value as string}
                            name={item.key}
                            className={classNames(
                              'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                            )}
                            onChange={(e) => {
                              const newFilters = appliedFilters.map((filter) => {
                                if (filter.key === item.key) {
                                  return {
                                    ...filter,
                                    value: cleanString(e.target.value),
                                  };
                                }
                                return filter;
                              });
                              setAppliedFilters(newFilters);

                              if (searchTimeout) clearTimeout(searchTimeout);
                              const timeoutAux = setTimeout(() => {
                                getData(newFilters);
                              }, 600);
                              setSearchTimeout(timeoutAux);
                            }}
                          />
                        )}
                        {item.type === 'number' && (
                          <>
                            <input
                              autoFocus
                              type="number"
                              placeholder="Desde"
                              value={(item.value as ITableFilterNumber).from}
                              name={`${item.key}_number_from`}
                              className={classNames(
                                'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                              )}
                              onChange={(e) => {
                                const newFilters = appliedFilters.map((filter) => {
                                  if (filter.key === item.key) {
                                    return {
                                      ...filter,
                                      value: {
                                        from:
                                          e.target.value !== ''
                                            ? Number(e.target.value)
                                            : undefined,
                                        to: (item.value as ITableFilterNumber).to,
                                      },
                                    };
                                  }
                                  return filter;
                                });
                                setAppliedFilters(newFilters);

                                if (searchTimeout) clearTimeout(searchTimeout);
                                const timeoutAux = setTimeout(() => {
                                  getData(newFilters);
                                }, 600);
                                setSearchTimeout(timeoutAux);
                              }}
                            />
                            <p className="mx-2">-</p>
                            <input
                              type="number"
                              placeholder="Hasta"
                              value={(item.value as ITableFilterNumber).to}
                              name={`${item.key}_number_to`}
                              className={classNames(
                                'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                              )}
                              onChange={(e) => {
                                const newFilters = appliedFilters.map((filter) => {
                                  if (filter.key === item.key) {
                                    return {
                                      ...filter,
                                      value: {
                                        from: (item.value as ITableFilterNumber).from,
                                        to: Number(e.target.value),
                                      },
                                    };
                                  }
                                  return filter;
                                });
                                setAppliedFilters(newFilters);

                                if (searchTimeout) clearTimeout(searchTimeout);
                                const timeoutAux = setTimeout(() => {
                                  getData(newFilters);
                                }, 600);
                                setSearchTimeout(timeoutAux);
                              }}
                            />
                          </>
                        )}
                        {item.type === 'date' && (
                          <>
                            <input
                              autoFocus
                              type="date"
                              placeholder="Desde"
                              value={(item.value as ITableFilterDate).from}
                              name={`${item.key}_date_from`}
                              className={classNames(
                                'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                              )}
                              onChange={(e) => {
                                const newFilters = appliedFilters.map((filter) => {
                                  if (filter.key === item.key) {
                                    return {
                                      ...filter,
                                      value: {
                                        option: '',
                                        from: e.target.value,
                                        to: (item.value as ITableFilterDate).to,
                                      },
                                    };
                                  }
                                  return filter;
                                });
                                setAppliedFilters(newFilters);

                                if (searchTimeout) clearTimeout(searchTimeout);
                                const timeoutAux = setTimeout(() => {
                                  getData(newFilters);
                                }, 600);
                                setSearchTimeout(timeoutAux);
                              }}
                            />
                            <p className="mx-2">-</p>
                            <input
                              type="date"
                              placeholder="Hasta"
                              value={(item.value as ITableFilterDate).to}
                              name={`${item.key}_date_to`}
                              className={classNames(
                                'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                              )}
                              onChange={(e) => {
                                const newFilters = appliedFilters.map((filter) => {
                                  if (filter.key === item.key) {
                                    return {
                                      ...filter,
                                      value: {
                                        option: '',
                                        from: (item.value as ITableFilterDate).from,
                                        to: e.target.value,
                                      },
                                    };
                                  }
                                  return filter;
                                });
                                setAppliedFilters(newFilters);

                                if (searchTimeout) clearTimeout(searchTimeout);
                                const timeoutAux = setTimeout(() => {
                                  getData(newFilters);
                                }, 600);
                                setSearchTimeout(timeoutAux);
                              }}
                            />
                          </>
                        )}
                        {item.type === 'select' && item.list && (
                          <Menu as="div" className="relative text-left">
                            <div>
                              <Menu.Button as={Fragment}>
                                <OutlineButton className="min-w-[180px]" size="sm">
                                  Elegir opciones <ChevronDownIcon className="ml-2 h-5 w-5" />
                                </OutlineButton>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute left-0 mt-2 max-h-80 w-56 origin-top-left divide-y divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1">
                                  <input
                                    type="search"
                                    placeholder="Buscar"
                                    value={selectSearch}
                                    onChange={(event) => setSelectSearch(event.target.value)}
                                    className="mb-1 w-full rounded-md border-gray-400 px-3 py-2 text-sm text-gray-900 shadow-sm transition-all focus:border-primary-base focus:ring-primary-base"
                                  />
                                  {item.list
                                    .filter((option) =>
                                      option.name.toLowerCase().includes(selectSearch.toLowerCase())
                                    )
                                    .map((option) => (
                                      <Menu.Item key={option.value}>
                                        {({ active }) => (
                                          <button
                                            className={`${active
                                                ? 'bg-primary-base text-white'
                                                : 'text-gray-900'
                                              } group flex w-full items-center justify-between rounded-md px-2 py-2 text-left text-sm`}
                                            onClick={() => {
                                              console.log(option);

                                              const newFilters = appliedFilters.map((filter) => {
                                                if (filter.key === item.key) {
                                                  if (
                                                    (filter.value as Array<any>).some(
                                                      (obj) =>
                                                        obj.value ===
                                                        (option.value?.value || option.value)
                                                    )
                                                  ) {
                                                    return {
                                                      ...filter,
                                                      value: (filter.value as Array<any>).filter(
                                                        (obj) =>
                                                          obj.value !==
                                                          (option.value?.value || option.value)
                                                      ),
                                                    };
                                                  } else {
                                                    return {
                                                      ...filter,
                                                      value: [
                                                        ...(filter.value as Array<any>),
                                                        option,
                                                      ],
                                                    };
                                                  }
                                                }
                                                return filter;
                                              });
                                              setAppliedFilters(newFilters);

                                              if (searchTimeout) clearTimeout(searchTimeout);
                                              const timeoutAux = setTimeout(() => {
                                                getData(newFilters);
                                              }, 600);
                                              setSearchTimeout(timeoutAux);
                                            }}
                                          >
                                            {option.name}
                                            {(item.value as Array<any>).some(
                                              (obj) => obj?.value === option.value
                                            ) && (
                                                <CheckIcon
                                                  className={classNames(
                                                    active ? 'text-white' : 'text-primary-base',
                                                    'h-5 w-5'
                                                  )}
                                                />
                                              )}
                                          </button>
                                        )}
                                      </Menu.Item>
                                    ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                        <button
                          className="group ml-2 rounded-lg py-2 px-2 outline-none transition-all duration-300 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-red-500"
                          onClick={() => {
                            const newFilters = appliedFilters.filter(
                              (filter) => filter.key !== item.key
                            );
                            setAppliedFilters(newFilters);
                            getData(newFilters);
                          }}
                        >
                          <TrashIcon className="h-5 w-5 text-red-500" />
                        </button>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        ))}
      </Popover.Group>
      {showFilters && (
        <Popover as="div" className="relative text-left">
          <div>
            <Popover.Button as={Fragment}>
              <OutlineButton className="w-auto" size="sm">
                Nuevo filtro <PlusCircleIcon className="ml-2 h-5 w-5" />
              </OutlineButton>
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-2 max-h-80 w-56 origin-top-right divide-y divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <input
                  type="search"
                  placeholder="Buscar"
                  value={filtersSearch}
                  onChange={(event) => setFiltersSearch(event.target.value)}
                  className="mb-1 w-full rounded-md border-gray-400 px-3 py-2 text-sm text-gray-900 shadow-sm transition-all focus:border-primary-base focus:ring-primary-base"
                />
                {shownColumns
                  .filter((column) =>
                    column.title.toLowerCase().includes(filtersSearch.toLowerCase())
                  )
                  .map((column) => {
                    const active = appliedFilters.some(
                      (item) => item?.key === (column.key || column.dataKey)
                    );
                    return (
                      <button
                        key={column.key || column.dataKey}
                        className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-left text-sm text-gray-900 transition-all hover:bg-primary-base hover:text-white"
                        onClick={() => {
                          if (active) {
                            setAppliedFilters(
                              appliedFilters.filter(
                                (item) => item?.key !== (column.key || column.dataKey)
                              )
                            );
                          } else {
                            setAppliedFilters([
                              ...appliedFilters,
                              {
                                key: column.key || column.dataKey,
                                title: column.title,
                                type: column.type || 'text',
                                value: DEFAULT_FILTERS[column.type || 'text'],
                                list: column.list || [],
                              },
                            ]);
                          }
                        }}
                      >
                        {column.title}
                        {active && (
                          <CheckIcon className="h-5 w-5 text-primary-base group-hover:text-white" />
                        )}
                      </button>
                    );
                  })}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
      <Popover as="div" className="relative text-left">
        <div>
          <Popover.Button as={Fragment}>
            <OutlineButton className="w-auto" size="sm">
              Columnas <ClipboardListIcon className="ml-2 h-5 w-5" />
            </OutlineButton>
          </Popover.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="absolute right-0 mt-2 max-h-80 w-56 origin-top-right divide-y divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <input
                type="search"
                placeholder="Buscar"
                value={columnsSearch}
                onChange={(event) => setColumnsSearch(event.target.value)}
                className="mb-1 w-full rounded-md border-gray-400 px-3 py-2 text-sm text-gray-900 shadow-sm transition-all focus:border-primary-base focus:ring-primary-base"
              />
              {columns
                .filter((column) =>
                  column.title.toLowerCase().includes(columnsSearch.toLowerCase())
                )
                .map((column) => {

                  const active = shownColumns.some(
                    (item) => item?.dataKey === (column.key || column.dataKey)
                  );
                  return (
                    <button
                      key={column.key || column.dataKey}
                      className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-left text-sm text-gray-900 transition-all hover:bg-primary-base hover:text-white"
                      onClick={() => {
                        let newColumns: Array<ITableColumn> = [];

                        // const currentPath = window.location.pathname;
                        let column2 = {
                          dataKey: column.dataKey,
                          title: column.title,
                          active: active,
                          column: column
                        }

                        if (currentPath === '/records') {
                          let storedColumns = localStorage.getItem('ShowColumns');
                          let counterArray = storedColumns ? JSON.parse(storedColumns) : [];

                          // Verificar si el objeto column2 ya existe en el array
                          const index = counterArray.findIndex((item: { dataKey: string }) => item.dataKey === column2.dataKey);

                          if (index !== -1) {
                            // Si existe, actualizarlo (reemplazar el objeto completo)
                            counterArray[index] = column2;
                          } else {
                            // Si no existe, agregar el objeto column2
                            counterArray.push(column2);
                          }

                          localStorage.setItem('ShowColumns', JSON.stringify(counterArray));
                        } else if (currentPath === '/data') {
                          let storedColumns = localStorage.getItem('ShowColumns2');
                          let counterArray = storedColumns ? JSON.parse(storedColumns) : [];

                          // Verificar si el objeto column2 ya existe en el array
                          const index = counterArray.findIndex((item: { dataKey: string }) => item.dataKey === column2.dataKey);

                          if (index !== -1) {
                            // Si existe, actualizarlo (reemplazar el objeto completo)
                            counterArray[index] = column2;
                          } else {
                            // Si no existe, agregar el objeto column2
                            counterArray.push(column2);
                          }

                          localStorage.setItem('ShowColumns2', JSON.stringify(counterArray));
                        }




                        if (active) {
                          newColumns = shownColumns.filter(
                            (item) => item?.dataKey !== (column.key || column.dataKey)
                          );
                        } else {
                          newColumns = [...shownColumns, column];
                        }
                        setShownColumns(
                          columns.filter((item) =>
                            newColumns.some((obj) => item?.dataKey === (obj.key || obj.dataKey))
                          )
                        );
                        const removeFilter = appliedFilters.some(
                          (filter) =>
                            !newColumns.some((obj) => filter?.key === (obj.key || obj.dataKey))
                        );
                        if (removeFilter) {
                          setAppliedFilters(
                            appliedFilters.filter((filter) =>
                              newColumns.some((obj) => filter?.key === (obj.key || obj.dataKey))
                            )
                          );
                          getData(
                            appliedFilters.filter((filter) =>
                              newColumns.some((obj) => filter?.key === (obj.key || obj.dataKey))
                            )
                          );
                        }
                      }}
                    >
                      {column.title}
                      {active && (
                        <CheckIcon className="h-5 w-5 text-primary-base group-hover:text-white" />
                      )}
                    </button>
                  );
                })}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
