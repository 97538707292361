import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

import LoadingSpinner from 'components/LoadingSpinner';
import { showErrorToast } from 'components/Toast';
import FiltersComponent from './Filters';
import Pagination from './Pagination';

import { ITable, ITableColumn, ITableFilter, ITableSort } from './interfaces';
import { classNames, formatDate, getObjectValue } from 'utils/functions';
import { SPINNER_COLORS } from 'utils/enums';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

export const bgClasses = {
  default: '',
  green: 'bg-green-600 text-gray-100',
  yellow: 'bg-yellow-400',
  red: 'bg-red-600 text-gray-100',
};

export default function Table({
  key = '_id',
  isLoading = false,
  setIsLoading,
  loadingText = 'Cargando',
  amountPerPage = 10,
  initialSort,
  columns,
  data,
  setData,
  dataApi,
  extraFilter = {},
  refresh,
  setRefresh,
  downloadAction,
  hideFilters = false,
  checkColumnsUpdate = false,
}: ITable) {
  const history = useNavigate();
  const auth = useAuth();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [appliedFilters, setAppliedFilters] = useState<Array<ITableFilter>>([]);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const [shownColumns, setShownColumns] = useState<Array<ITableColumn>>(
    columns.filter((obj) => !obj.hidden)
  );

  const [exportLoading, setExportLoading] = useState(false);

  const [sort, setSort] = useState<ITableSort>(
    initialSort || {
      key: columns[0].key,
      order: 'desc',
    }
  );

  const processedRows = useMemo(() => {
    if (!data) return [];
    const order = sort.order === 'desc' ? -1 : 1;
    return dataApi
      ? data
      : data
          ?.sort((a, b) => {
            if (a[sort.key] < b[sort.key]) {
              return -1 * order;
            }
            if (a[sort.key] > b[sort.key]) {
              return 1 * order;
            }
            return 0;
          })
          ?.slice(amountPerPage * (page - 1), amountPerPage * page);
  }, [data, dataApi, sort, amountPerPage, page]);

  const getData = async (filters?: Array<ITableFilter>, newSort?: ITableSort, newPage?: number) => {
    if (!dataApi || isLoading) return;
    setIsLoading(true);
    let result = await dataApi.getContent(
      newPage || page,
      {
        ...extraFilter,
        ...(filters || appliedFilters).reduce((acc: any, filter) => {
          acc[filter.key] = filter.value;
          return acc;
        }, {}),
      },
      newSort || sort
    );
    if (!result.success) {
      setIsLoading(false);
      return showErrorToast(result.message);
    }
    setData(result.data.items);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setIsLoading(false);
  };

  const defaultExport = async () => {
    if (!generalApi || !dataApi || exportLoading) return;
    setExportLoading(true);
    const result = await generalApi.post(dataApi.apiPath, {
      pagination: {
        page: 1,
        amount: 10_000_000,
      },
      filter: {
        ...extraFilter,
        ...appliedFilters.reduce((acc: any, filter) => {
          acc[filter.key] = filter.value;
          return acc;
        }, {}),
      },
      sort,
    });
    setExportLoading(false);
    if (!result.success || !result.data || result.data.items.length === 0)
      return showErrorToast('No hay información para exportar');
    const exportData = result.data.items;
    const createXLSLFormatObj = [];
    const xlsHeader = ['#', ...shownColumns.map((obj) => obj.title)];
    createXLSLFormatObj.push(xlsHeader);
    for (let i = 0; i < exportData.length; i++) {
      const item = exportData[i];
      const innerRowData = [];
      innerRowData.push(i + 1 + '');
      console.log('item', item);
      for (let n = 0; n < shownColumns.length; n++) {
        const element = shownColumns[n];
        const value = getObjectValue(item, element.dataKey);
       // console.log('value', value , element.dataKey);
       console.log('element.type', element.type);
        if (element.type === 'date') {
          innerRowData.push(value ? formatDate(value, 'dd/MM/yyyy', true) : '-');
        } else if (element.type === 'combo') {
          innerRowData.push(
            element.list?.map((field) => getObjectValue(item, field) || '').join(' ')
          );
        } else if (element.type === 'select') {
          console.log('value', value );
          console.log('element.list', element.list);
          innerRowData.push(element.list?.find((obj) => obj.value === value)?.name || '-');
        } else if (element.type === 'obj') {
          console.log('value', value );
          console.log('element.list', element.list);
          const sumillas = value?.map((s: any) => `${s.titulo}`).join(' // ') || '';
          innerRowData.push(sumillas);
        } 
        else {
          innerRowData.push(value || '-');
          console.log('value', value , element.dataKey);
        }
      }
      console.log('innerRowData', innerRowData);
      createXLSLFormatObj.push(innerRowData);
    }
   // console.log('createXLSLFormatObj', createXLSLFormatObj);
    const filename = 'Reporte.xlsx';
    const ws_name = 'Reporte';
    const wb = utils.book_new(),
      ws = utils.aoa_to_sheet(createXLSLFormatObj);
    utils.book_append_sheet(wb, ws, ws_name);
    writeFile(wb, filename);
  };

  useEffect(() => {
    if (refresh || refresh === undefined) {
      getData();
      setRefresh?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraFilter, refresh]);

  useEffect(() => {
    if (checkColumnsUpdate) setShownColumns(columns.filter((obj) => !obj.hidden));
  }, [columns, checkColumnsUpdate]);

  return (
    <div className="shadow-md">
      <FiltersComponent
        showFilters={!!dataApi && !hideFilters}
        appliedFilters={appliedFilters}
        searchTimeout={searchTimeout}
        columns={columns}
        shownColumns={shownColumns}
        setShownColumns={setShownColumns}
        setAppliedFilters={setAppliedFilters}
        setSearchTimeout={setSearchTimeout}
        getData={getData}
      />

      <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
        <table className="min-w-full divide-gray-200">
          <thead>
            <tr>
              {shownColumns.map((column) => (
                <th
                  key={column.key ?? column.dataKey}
                  onClick={() => {
                    if (column.disableSort) return;
                    const newSort = {
                      key: column.key ?? column.dataKey,
                      order: (sort.key === (column.key ?? column.dataKey) && sort.order === 'desc'
                        ? 'asc'
                        : 'desc') as 'asc' | 'desc',
                    };
                    setSort(newSort);
                    getData(undefined, newSort);
                  }}
                  className="group cursor-pointer bg-accent px-3 py-3 text-left text-sm font-medium uppercase tracking-wide text-gray-700 transition hover:bg-accent-dark"
                >
                  <div className="flex items-center justify-between">
                    <span className="truncate">{column.title}</span>
                    {!column.disableSort && sort.key === (column.key ?? column.dataKey) ? (
                      <span className="ml-1 flex-none rounded bg-accent-dark text-gray-100 transition group-hover:bg-accent">
                        {sort.order === 'desc' ? (
                          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    ) : !column.disableSort ? (
                      <span className="invisible ml-1 flex-none rounded text-gray-100 group-hover:visible group-focus:visible">
                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <Transition
            show={!isLoading}
            as="tbody"
            className="w-full divide-y divide-gray-200 bg-white"
          >
            {processedRows.map((item, index) => (
              <tr key={item[key]} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-body'}>
                {shownColumns.map((column) => (
                  <td
                    key={column.key || column.dataKey}
                    className={classNames(
                      column.backgroundColor
                        ? bgClasses[column.backgroundColor(item)]
                        : 'text-gray-700',
                      'whitespace-nowrap px-3 py-4 text-left text-base'
                    )}
                  >
                    {column.render
                      ? column.render(item)
                      : getObjectValue(item, column.dataKey) || column.defaultValue || '-'}
                  </td>
                ))}
              </tr>
            ))}
          </Transition>
        </table>
      </div>
      <Transition
        show={isLoading}
        className="flex w-full items-center justify-center bg-white py-8 px-4"
      >
        <LoadingSpinner color={SPINNER_COLORS.GREEN} content={loadingText} />
      </Transition>
      <Transition
        show={!isLoading && (!data || data?.length === 0)}
        className="flex w-full items-center justify-center bg-white py-8 px-4"
      >
        No se encontraron resultados
      </Transition>
      <Pagination
        page={page}
        amountPerPage={amountPerPage}
        total={dataApi ? total : data?.length}
        maxPage={
          dataApi
            ? maxPage
            : data?.length % amountPerPage === 0
            ? data?.length / amountPerPage
            : Math.floor(data?.length / amountPerPage) + 1
        }
        onPreviousPage={() => {
          setPage(page - 1);
          getData(undefined, undefined, page - 1);
        }}
        onNextPage={() => {
          setPage(page + 1);
          getData(undefined, undefined, page + 1);
        }}
        downloadShown={Boolean(generalApi && dataApi)}
        downloadAction={downloadAction || defaultExport}
        downloadLoading={exportLoading}
      />
    </div>
  );
}
