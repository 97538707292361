import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { addDays } from 'date-fns';

import { ExclamationIcon, SearchIcon, TrashIcon } from '@heroicons/react/outline';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import PrivateRoute from 'components/Navigation/ProtectedRoute';
import { IFile } from 'components/FileUpload/interfaces';
import FileUploadComponent from 'components/FileUpload';
import OutlineButton from 'components/OutlineButton';
import BaseInput from 'components/Inputs/BaseInput';
import SolidButton from 'components/SolidButton';
import StepTitle from 'components/StepTitle';
import Card from 'components/Card';

import {
  RECORD_CONSISTENCY_OPTIONS,
  RECORD_STATUS_OPTIONS,
  RECORD_STATUS_TEXT,
  REQUIRED_RULE,
} from 'utils/constants';
import { addWorkDays, classNames, formatDate, formatNumber } from 'utils/functions';
import { RECORD_STATUS, RECORD_STEPS } from 'utils/enums';
import useLicense from 'hooks/useLicense';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

export default function NewRecordPage() {
  const {
    register,
    watch,
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      general_status: 'valid',
    },
  });
  const [licenseLoading, licenseExpired, overLimit] = useLicense();
  const history = useNavigate();
  const auth = useAuth();
  const statusRef = useRef<any>();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [files, setFiles] = useState<Array<IFile>>([]);

  const [editingStep, setEditingStep] = useState<RECORD_STEPS | undefined>();

    const [diferencia, setDiferencia] = useState('');

  const [sumilla, setSumilla] = useState({ titulo: '', fecha: '' });
  const [sumillasList, setSumillasList] = useState<{ titulo: string; fecha: string; }[]>([]); 

  const [sumilla2, setSumilla2] = useState({ titulo: '', fecha: '' });
  const [sumillasList2, setSumillasList2] = useState<{ titulo: string; fecha: string; }[]>([]); 

  const status = watch('status');
  const dailySalary = watch('daily_salary');
  const emittedAt = watch('emitted_at');
  const dmStart = watch('dm_start_at');
  const dmEnd = watch('dm_end_at');
  const resolvedReceivedAt = watch('resolved_received_at');
  const chargedPayAt = watch('charged_pay_at');
  const chargedAt = watch('charged_at');
  const refundAt = watch('refund_at');

  const essaludAmount = watch('charged_essalud_amount');
  const loanAmount = watch('loan_cr_total');
  const dailyAmount = watch('loan_cr_amount');
  const observationAt = watch('observation_at');

  const daysDiff = useMemo(() => {
    const start = new Date(dmStart);
    const end = new Date(dmEnd);
    const diff = end.getTime() - start.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
    return days;
  }, [dmStart, dmEnd]);

  const searchWorker = async () => {
    if (getValues('doc_number') === '') return;
    setSearchLoading(true);
    let result: any = await generalApi.post('/records/details', {
      docNumber: getValues('doc_number'),
    });
    setSearchLoading(false);
    if (!result.success || !result.data) return showErrorToast(result.message);
    showSuccessToast('Expediente encontrado');
    setValue('doc_type', result.data.doc_type);
    setValue('store', result.data.store);
    setValue('name', result.data.name);
    setValue('worker_status', result.data.worker_status);
    setValue(
      'worker_start_at',
      result.data.worker_start_at ? formatDate(result.data.worker_start_at, 'yyyy-MM-dd') : ''
    );
    setValue(
      'worker_end_at',
      result.data.worker_end_at ? formatDate(result.data.worker_end_at, 'yyyy-MM-dd') : ''
    );
    setValue('position', result.data.position);
    setValue('phone', result.data.phone);
    setValue('email', result.data.email);
    setValue('gender', result.data.gender);
    setValue('daily_salary', result.data.daily_salary);
  };

  const saveData = async () => {
    if (!(await trigger())) return showErrorToast('Complete todos los campos requeridos');
    setSubmitLoading(true);
    const data = getValues();
    const subsidy_year = formatDate(dmStart, 'yyyy');
    const subsidy_month = formatDate(dmStart, 'MM');
    const code = `${data.doc_number}-${formatDate(data.dm_start_at, 'ddMMyyyy')}${formatDate(
      data.dm_end_at,
      'ddMMyyyy'
    )}`;

    data.sumilla = sumillasList; // Agregar sumillasList directamente al objeto data
    data.sumilla2 = sumillasList2; // Agregar sumillasList directamente al objeto data

    let result: any = await generalApi.post('/records', {
      code,
      amount: daysDiff && dailySalary ? daysDiff * dailySalary : undefined,
      dm_days: daysDiff,
      files,
      subsidy_year,
      subsidy_month,
      ...data,
    });
    setSubmitLoading(false);
    if (!result.success) return showErrorToast(result.message);
    showSuccessToast('Expediente creado');
    setTimeout(function () {
      history(`/record/${code}`);
    }, 4000);
  };

  // Función para manejar el input de sumilla
  const handleChange = (e:any) => {
    setSumilla({
      ...sumilla,
      [e.target.name]: e.target.value
    });
  };
  const handleChange2 = (e:any) => {
    setSumilla2({
      ...sumilla2,
      [e.target.name]: e.target.value
    });
  };

  // Función para agregar la sumilla a la lista
  const agregarSumilla = () => {

    if (sumilla.titulo) {

      const fechaActual = new Date();
      const fechaFormateada = `${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, '0')}-${String(fechaActual.getDate()).padStart(2, '0')} ${String(fechaActual.getHours()).padStart(2, '0')}:${String(fechaActual.getMinutes()).padStart(2, '0')}`;

      sumilla.fecha = fechaFormateada;
      setSumillasList([...sumillasList, sumilla]);
      setSumilla({ titulo: '', fecha: '' }); // Limpiar el input
    } else {
      alert("Ambos campos son requeridos");
    }
  };

  const agregarSumilla2 = () => {

    if (sumilla2.titulo) {

      const fechaActual = new Date();
      const fechaFormateada = `${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, '0')}-${String(fechaActual.getDate()).padStart(2, '0')} ${String(fechaActual.getHours()).padStart(2, '0')}:${String(fechaActual.getMinutes()).padStart(2, '0')}`;

      sumilla2.fecha = fechaFormateada;
      setSumillasList2([...sumillasList2, sumilla2]);
      setSumilla2({ titulo: '', fecha: '' }); // Limpiar el input
    } else {
      alert("Ambos campos son requeridos");
    }
  };

   // Función para eliminar una sumilla de la lista
  const eliminarSumilla = (index: number) => {
    setSumillasList((prevList) => prevList.filter((_, i) => i !== index)); // Filtrar la lista para eliminar el item
  };
  const eliminarSumilla2 = (index: number) => {
    setSumillasList2((prevList) => prevList.filter((_, i) => i !== index)); // Filtrar la lista para eliminar el item
  };


  const buildStepTitle = (title: string, step: RECORD_STEPS) => (
    <StepTitle
      title={title}
      loading={submitLoading}
      step={step}
      currentStep={editingStep}
      setCurrentStep={setEditingStep}
    />
  );

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        placeholder={`Ingrese su ${label}`}
        type={type}
        name={name}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        control={control}
        rules={rules}
        disabled={overLimit || licenseExpired}
        {...props}
      />
    );
  };

  useEffect(() => {
    if (observationAt)
      setValue(
        'fix_deadline_at',
        formatDate(addWorkDays(new Date(observationAt), 30), 'yyyy-MM-dd')
      );
  }, [observationAt, setValue]);

  useEffect(() => {
    if (emittedAt)
      setValue(
        'exchange_deadline_at',
        formatDate(addWorkDays(new Date(emittedAt), 30), 'yyyy-MM-dd')
      );
  }, [emittedAt, setValue]);

  useEffect(() => {
    if (dmEnd)
      setValue('loan_deadline_at', formatDate(addDays(new Date(dmEnd), 180), 'yyyy-MM-dd'));
  }, [dmEnd, setValue]);

  useEffect(() => {
    if (resolvedReceivedAt)
      setValue(
        'resolved_deadline_at',
        formatDate(addWorkDays(new Date(resolvedReceivedAt), 15), 'yyyy-MM-dd')
      );
  }, [resolvedReceivedAt, setValue]);

  useEffect(() => {
    if (chargedPayAt)
      setValue(
        'charged_limit_at',
        formatDate(addWorkDays(new Date(chargedPayAt), 20), 'yyyy-MM-dd')
      );
  }, [chargedPayAt, setValue]);

  useEffect(() => {
    if (chargedAt)
      setValue(
        'refund_deadline_at',
        formatDate(addWorkDays(new Date(chargedAt), 15), 'yyyy-MM-dd')
      );
  }, [chargedAt, setValue]);

  useEffect(() => {
    if (loanAmount && essaludAmount !== undefined) {
      //setValue('charged_difference', loanAmount - essaludAmount);

      const daysDiffTemp = Math.round(loanAmount - essaludAmount);
      if(daysDiffTemp>= 0) {
        setDiferencia('S/ (diferencia) ');
      }else {
        setDiferencia('S/ (saldo a favor) ');
      }

      setValue('charged_difference',  Math.round(loanAmount - essaludAmount));
      setValue('refund_amount', loanAmount - essaludAmount);
    }
  }, [essaludAmount, loanAmount, setValue]);

  useEffect(() => {
    if (dailyAmount && daysDiff) setValue('loan_cr_total', Math.round(dailyAmount * daysDiff));
  }, [dailyAmount, daysDiff, setValue]);

  return (
    <PrivateRoute>
      <form
        className="mx-auto h-full max-w-7xl px-1 pt-8 text-[#1B205A] lg:pt-24 lg:pb-8"
        onSubmit={handleSubmit(saveData)}
      >
        {(overLimit || licenseExpired) && (
          <div className="mb-8 rounded-lg border-l-4 border-red-400 bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="font-medium text-red-800">
                  {licenseExpired
                    ? 'Licencia expirada, por favor contacte a soporte'
                    : 'Límite alcanzado.'}
                  {/* <a
                    href="#"
                    className="font-medium text-red-700 underline hover:text-red-600"
                  >
                    Upgrade your account to add more credits.
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <h1 className="mb-4 text-xl font-bold text-primary-base sm:text-3xl">NUEVO EXPEDIENTE</h1>
          <OutlineButton className="w-auto" onClick={() => history(-1)}>
            Volver
          </OutlineButton>
        </div>
        <div
          ref={statusRef}
          className="mb-8 grid gap-4 border-b border-gray-400 py-12 lg:grid-cols-3 lg:gap-x-8"
        >
          {buildBaseInput(
            'Estado General',
            'select',
            'general_status',
            {},
            {
              options: [
                { value: 'valid', name: 'VIGENTE' },
                { value: 'closed', name: 'CERRADO' },
              ],
            }
          )}
          {buildBaseInput(
            'Status',
            'select',
            'status',
            {},
            {
              options: [
                RECORD_STATUS.NEW,
                RECORD_STATUS.UPE,
                RECORD_STATUS.IN_EXCHANGE,
                RECORD_STATUS.IN_LOAN,
                RECORD_STATUS.RESOLVED,
                RECORD_STATUS.CHARGED,
                RECORD_STATUS.DISCOUNT,
                RECORD_STATUS.NOT_RECOVERED,
                RECORD_STATUS.REACTIVATION,
              ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
              onChange: (value: string) => {
                setValue('status', value);
                setValue(
                  'status_detail',
                  RECORD_STATUS_OPTIONS[value as RECORD_STATUS]?.[0]?.value
                );
              },
            }
          )}
          {status !== RECORD_STATUS.DISCOUNT &&
            buildBaseInput(
              'Detalle de status',
              'select',
              'status_detail',
              {},
              {
                options: RECORD_STATUS_OPTIONS[status as RECORD_STATUS],
              }
            )}
            {buildBaseInput('Fecha de recepción de expediente', 'date', 'reception_exp_at', {}, {})}
        </div>

        <Card
          className="mb-8"
          title={
            <div className="items-center justify-between md:flex">
              <span className="text-xl font-bold">DATOS DEL COLABORADOR</span>
              <OutlineButton
                size="sm"
                className="w-auto"
                onClick={() => {
                  setValue('doc_number', '');
                  setValue('doc_type', '');
                  setValue('store', '');
                  setValue('name', '');
                  setValue('worker_status', '');
                  setValue('worker_start_at', '');
                  setValue('worker_end_at', '');
                  setValue('position', '');
                  setValue('phone', '');
                  setValue('email', '');
                  setValue('gender', '');
                  setValue('daily_salary', '');
                }}
              >
                Limpiar
                <TrashIcon className="ml-2 h-4 w-4" />
              </OutlineButton>
            </div>
          }
        >
          <div
            className={classNames(
              editingStep === RECORD_STEPS.WORKER ? 'lg:gap-x-8' : 'lg:gap-x-8',
              'grid gap-4 lg:grid-cols-3'
            )}
          >
            {buildBaseInput('Tipo de documento', 'select', 'doc_type', REQUIRED_RULE, {
              options: [{ name: 'DNI' }, { value: 'CE', name: 'C.E.' }],
            })}
            {buildBaseInput('Número de documento', 'text', 'doc_number', REQUIRED_RULE, {
              buttonContent: searchLoading ? (
                <svg
                  className="h-5 w-5 animate-spin text-primary-base"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <SearchIcon className="h-4 w-4" />
              ),
              buttonAction: () => searchWorker(),
            })}
            {buildBaseInput('Sede', 'text', 'store', {}, {})}
            {buildBaseInput('Apellidos y nombres', 'text', 'name', {}, {})}
            {buildBaseInput(
              'Estado de trabajador',
              'select',
              'worker_status',
              {},
              {
                options: [{ name: 'ACTIVO' }, { name: 'CESADO' }],
              }
            )}
            {buildBaseInput('Fecha de ingreso', 'date', 'worker_start_at', {}, {})}
            {buildBaseInput('Fecha de cese', 'date', 'worker_end_at', {}, {})}
            {buildBaseInput('Cargo', 'text', 'position', {}, {})}
            {buildBaseInput('Telefono', 'text', 'phone', {}, { textUppercase: false })}
            {buildBaseInput(
              'Email',
              'email',
              'email',
              {},
              {
                textUppercase: false,
              }
            )}
            {buildBaseInput(
              'Sexo',
              'select',
              'gender',
              {},
              {
                options: [{ name: 'MASCULINO' }, { name: 'FEMENINO' }],
              }
            )}
            {buildBaseInput('Remuneración diaria aprox', 'number', 'daily_salary', REQUIRED_RULE, {
              prefix: 'S/ ',
            onBlur: (value: React.FocusEvent<HTMLInputElement>) => {
                setValue('daily_salary', Math.round(+value.target.value));
              },
            })}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle('DATOS DEL DESCANSO MÉDICO', RECORD_STEPS.MEDICAL_REST)}
        >
          <div
            className={classNames(
              editingStep === RECORD_STEPS.WORKER ? 'lg:gap-x-8' : 'lg:gap-x-8',
              'grid gap-4 lg:grid-cols-3'
            )}
          >
            {buildBaseInput(
              'Contingencia',
              'select',
              'contingency',
              {},
              {
                options: RECORD_CONSISTENCY_OPTIONS,
              }
            )}
            {buildBaseInput(
              'Tipo D.M',
              'select',
              'dm_type',
              {},
              {
                options: [{ name: 'ESSALUD' }, { name: 'PARTICULAR' }],
              }
            )}
            {buildBaseInput('Diagnóstico', 'text', 'diagnosis', {}, {})}
            {buildBaseInput(
              'Diagnóstico General',
              'select',
              'general_diagnosis',
              {},
              {
                options: [
                  { name: 'ACCIDENTES' },
                  { name: 'ALTERACIONES DE LA PIEL Y ANEXOS' },
                  { name: 'ALTERACIONES DEL APARATO RESPIRATORIO' },
                  { name: 'ALTERACIONES DEL APARATO DIGESTIVO' },
                  { name: 'ALTERACIONES OTORRINOLARINGOLÓGICAS' },
                  { name: 'ALTERACIONES DENTALES' },
                  { name: 'ALTERACIONES GENITOURINARIAS' },
                  { name: 'ALTERACIONES GINECOLÓGICAS' },
                  { name: 'ALTERACIONES MÚSCULO ESQUELÉTICAS' },
                  { name: 'ALTERACIONES NEUROLÓGICAS' },
                  { name: 'ALTERACIONES PSIQUIÁTRICAS' },
                  { name: 'ALTERACIONES OFTALMOLÓGICAS' },
                  { name: 'ALTERACIONES CARDIOVASCULARES' },
                  { name: 'ALTERACIONES ONCOLÓGICAS' },
                  { name: 'ALTERACIONES INFECCIOSAS (INC. COVID-19 Y SECUELAS)' },
                  { name: 'HOSPITALIZACIÓN' },
                  { name: 'MATERNIDAD PRE Y POST PARTO' },
                  { name: 'OTROS' },
                ],
              }
            )}
            {buildBaseInput(
              'Especialidad',
              'select',
              'specialty',
              {},
              {
                options: [
                  { value: 'CARDIOLOGIA', name: 'CARDIOLOGIA' },
                  { value: 'CIRUGIA', name: 'CIRUGIA' },
                  { value: 'DERMATOLOGIA', name: 'DERMATOLOGIA' },
                  { value: 'ENDOCRINOLOGIA', name: 'ENDOCRINOLOGIA' },
                  { value: 'ESTOMATOLOGIA (DENTAL)', name: 'ESTOMATOLOGIA (DENTAL)' },
                  { value: 'GASTROENTEROLOGIA', name: 'GASTROENTEROLOGIA' },
                  { value: 'GINECOLOGIA', name: 'GINECOLOGIA' },
                  { value: 'HEMATOLOGIA', name: 'HEMATOLOGIA' },
                  {
                    value: 'MEDICINA FISICA Y REHABILITACION',
                    name: 'MEDICINA FISICA Y REHABILITACION',
                  },
                  { value: 'MEDICINA GENERAL', name: 'MEDICINA GENERAL' },
                  { value: 'MEDICINA INTERNA', name: 'MEDICINA INTERNA' },
                  { value: 'NEFROLOGIA', name: 'NEFROLOGIA' },
                  { value: 'NEUMOLOGIA', name: 'NEUMOLOGIA' },
                  { value: 'NEUROLOGIA', name: 'NEUROLOGIA' },
                  { value: 'OBSTETRICIA (MATERNIDAD)', name: 'OBSTETRICIA (MATERNIDAD)' },
                  { value: 'OFTALMOLOGIA', name: 'OFTALMOLOGIA' },
                  { value: 'ONCOLOGIA', name: 'ONCOLOGIA' },
                  { value: 'OTORRINOLARINGOLOGIA', name: 'OTORRINOLARINGOLOGIA' },
                  { value: 'PSIQUIATRIA', name: 'PSIQUIATRIA' },
                  { value: 'TRAUMATOLOGIA', name: 'TRAUMATOLOGIA' },
                  { value: 'UROLOGIA', name: 'UROLOGIA' },
                  { value: 'OTROS', name: 'OTROS' },
                ],
              }
            )}
            {buildBaseInput('Centro medico', 'text', 'center', {}, {})}
            {buildBaseInput('Nombre del médico', 'text', 'doctor_name', {}, {})}
            {buildBaseInput('C.M.P', 'text', 'cmp', {}, {})}
            {buildBaseInput('Fecha de emisión', 'date', 'emitted_at', {}, {})}
            {buildBaseInput('F. inicio D.M', 'date', 'dm_start_at', REQUIRED_RULE, {})}
            {buildBaseInput('F. fin D.M', 'date', 'dm_end_at', REQUIRED_RULE, {})}
          </div>
          {dmStart && dmEnd && dailySalary && (
            <div className="mt-8 flex justify-around text-center">
              <div>
                <p>Días</p>
                <p className="text-xl font-bold">{daysDiff}</p>
              </div>
              <div>
                <p>Año de Subsidio</p>
                <p className="text-xl font-bold">{formatDate(dmStart, 'yyyy')}</p>
              </div>
              <div>
                <p>Mes de Subsidio</p>
                <p className="text-xl font-bold">{formatDate(dmStart, 'MMMM')}</p>
              </div>
              <div>
                <p>Importe Aproximado de Subsidio</p>
                <p className="text-xl font-bold">S/ {formatNumber(daysDiff * dailySalary)}</p>
              </div>
            </div>
          )}
        </Card>

        <Card className="mb-8" title={buildStepTitle('VALIDACIÓN DE D.M', RECORD_STEPS.VALIDATION)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              'F. limite de canje',
              'date',
              'exchange_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput('F. de gestión de canje', 'date', 'exchange_at', {}, {})}
            {buildBaseInput('F. de comunicación al trabajador', 'date', 'worker_comm_at', {}, {})}
            {buildBaseInput('NIT de canje', 'text', 'nitt', {}, {})}
            {buildBaseInput('N° de CITT', 'text', 'citt', {}, {})}
            {buildBaseInput('F. de validación CITT', 'date', 'citt_at', {}, {})}
            {buildBaseInput('F. de observación', 'date', 'observation_at', {}, {})}
            {buildBaseInput(
              'F. limite para subsanar',
              'date',
              'fix_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput('F. de subsanación', 'date', 'fix_at', {}, {})}
            {buildBaseInput(
              'Comentarios adicionales',
              'text',
              'comment',
              {},
              {
                parentClass: 'col-span-3',
              }
            )}
            {/* Nuevos campos agregados debajo del importe total de subsidio */}
            <div className="lg:col-span-3 mt-4 flex flex-col lg:flex-row items-center">
              <div className="flex-1 mr-8 w-2/3">
                <label htmlFor="titulo" className="block text-sm font-medium text-gray-700">
                  Título de la sumilla
                </label>
                <input
                  type="text"
                  name="titulo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  value={sumilla2.titulo}
                  onChange={handleChange2}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevenir el comportamiento por defecto
                    }
                  }} // Prevenir el envío del formulario
                />
              </div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-md mt-5"
                onClick={agregarSumilla2}
              >
                Agregar Sumilla
              </button>
            </div>

            {sumillasList2.length > 0 && (
              <div className="lg:col-span-3 mt-4 overflow-x-auto"> {/* Añadir overflow-x-auto para manejar el desbordamiento */}
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SUMILLA
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Fecha y hora
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sumillasList2.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.titulo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.fecha} {/* Cambiar esto a item.fecha si estás mostrando la fecha */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <button
                            onClick={(event) => {
                              event.preventDefault(); // Previene el comportamiento por defecto
                              eliminarSumilla2(index); // Llama a la función de eliminar
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <TrashIcon className="h-5 w-5 inline" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle(
            'GESTIÓN DE PRESTACIONES - REEMBOLSO DE SUBSIDIO',
            RECORD_STEPS.IN_LOAN
          )}
        >
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              'F. limite de prestaciones',
              'date',
              'loan_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput('F. gestion UPE', 'date', 'loan_upe_at', {}, {})}
            {buildBaseInput('NIT de prestaciones', 'text', 'loan_nitt', {}, {})}
            {buildBaseInput(
              'Importe diario CR',
              'number',
              'loan_cr_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {/* {buildBaseInput('F. de observacion UPE', 'date', 'loan_observation_upe_at', {}, {})}
            {buildBaseInput('F. limite para subsanar UPE', 'date', 'loan_deadline_upe_at', {}, {})}
            {buildBaseInput('F. de subsanación UPE', 'date', 'loan_fix_upe_at', {}, {})} */}
            {buildBaseInput(
              'Comentarios adicionales',
              'text',
              'loan_comment',
              {},
              {
                parentClass: 'col-span-2',
              }
            )}
            {loanAmount && (
              <div className="col-span-3 mt-4 flex flex-col items-center">
                <p>Importe total de subsidio (C.R.)</p>
                <p className="text-xl font-bold">S/ {loanAmount}</p>
              </div>
            )}
            {/* Nuevos campos agregados debajo del importe total de subsidio */}
            <div className="lg:col-span-3 mt-4 flex flex-col lg:flex-row items-center">
              <div className="flex-1 mr-8 w-2/3">
                <label htmlFor="titulo" className="block text-sm font-medium text-gray-700">
                  Título de la sumilla
                </label>
                <input
                  type="text"
                  name="titulo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  value={sumilla.titulo}
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevenir el comportamiento por defecto
                    }
                  }} // Prevenir el envío del formulario
                />
              </div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-md mt-5"
                onClick={agregarSumilla}
              >
                Agregar Sumilla
              </button>
            </div>

            {sumillasList.length > 0 && (
              <div className="lg:col-span-3 mt-4 overflow-x-auto"> {/* Añadir overflow-x-auto para manejar el desbordamiento */}
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SUMILLA
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Fecha y hora
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sumillasList.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.titulo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.fecha} {/* Cambiar esto a item.fecha si estás mostrando la fecha */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <button
                            onClick={(event) => {
                              event.preventDefault(); // Previene el comportamiento por defecto
                              eliminarSumilla(index); // Llama a la función de eliminar
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <TrashIcon className="h-5 w-5 inline" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle('GESTIÓN DE PRESTACIONES - RESOLUCIÓN', RECORD_STEPS.RESOLVED)}
        >
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput('Fecha recepción resolución', 'date', 'resolved_received_at', {}, {})}
            {buildBaseInput('N° de resolucion', 'text', 'resolved_number', {}, {})}
            {buildBaseInput('Motivo resolucion', 'text', 'resolved_motive', {}, {})}
            {buildBaseInput(
              'F. límite respuesta resolución',
              'date',
              'resolved_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {buildBaseInput('F. de respuesta resolución', 'date', 'resolved_response_at', {}, {})}
            {buildBaseInput('Comentarios adicionales', 'text', 'resolved_comment', {}, {})}
          </div>
        </Card>

        <Card
          className="mb-8"
          title={buildStepTitle(
            'GESTIÓN DE PRESTACIONES - RECEPCIÓN DE PAGO',
            RECORD_STEPS.CHARGED
          )}
        >
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput('Fecha de pago', 'date', 'charged_pay_at', {}, {})}
            {buildBaseInput('F. límite para cobrar subsidio', 'date', 'charged_limit_at', {}, {})}
            {buildBaseInput('Fecha de cobro', 'date', 'charged_at', {}, {})}
            {buildBaseInput(
              'Importe EsSalud',
              'number',
              'charged_essalud_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput(
              'Diferencia',
              'number',
              'charged_difference',
              {},
              {
                // prefix: 'S/ ',
                prefix: diferencia,
                disabled: true,
              }
            )}
            {buildBaseInput('N° expediente', 'text', 'charged_number', {}, {})}
            {buildBaseInput('N° voucher', 'text', 'charged_voucher', {}, {})}
            {buildBaseInput(
              'Comentarios adicionales',
              'text',
              'charged_comment',
              {},
              {
                parentClass: 'col-span-2',
              }
            )}
          </div>
        </Card>

        <Card className="mb-8" title={buildStepTitle('REINTEGRO', RECORD_STEPS.PARTIAL_REFUND)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput(
              'F. límite solicitud de reintegro',
              'date',
              'refund_deadline_at',
              {},
              {
                disabled: true,
              }
            )}
            {refundAt !== '' &&
              buildBaseInput(
                'Importe Reintegro',
                'number',
                'refund_amount',
                {},
                {
                  prefix: 'S/ ',
                }
              )}
            {buildBaseInput('F. de solicitud reintegro', 'date', 'refund_at', {}, {})}
            {buildBaseInput('Nit solicitud de reintegro', 'number', 'refund_nit', {}, {})}
            {buildBaseInput('N° expediente reintegro', 'text', 'refund_number', {}, {})}
            {buildBaseInput('F. de pago reintegro', 'date', 'refund_payment_at', {}, {})}
            {buildBaseInput('F. de cobro reintegro', 'date', 'refund_collection_at', {}, {})}
            {buildBaseInput('N° voucher reintegro', 'text', 'refund_voucher', {}, {})}
            {buildBaseInput('Comentarios adicionales', 'text', 'refund_comment', {}, {})}
          </div>
        </Card>

        <Card className="mb-8" title={buildStepTitle('DESCUENTO', RECORD_STEPS.DISCOUNT)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput('F. descuento', 'date', 'discount_at', {}, {})}
            {buildBaseInput(
              'Importe descontado',
              'number',
              'discount_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput('Comentarios adicionales', 'text', 'discount_comment', {}, {})}
          </div>
        </Card>

        <Card className="mb-8" title={buildStepTitle('NO RECUPERADO', RECORD_STEPS.NOT_RECOVERED)}>
          <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-8">
            {buildBaseInput('F. pase a No recuperado', 'date', 'not_recovered_at', {}, {})}
            {buildBaseInput(
              'Importe no recuperado',
              'number',
              'not_recovered_amount',
              {},
              {
                prefix: 'S/ ',
              }
            )}
            {buildBaseInput('Comentarios adicionales', 'text', 'not_recovered_comment', {}, {})}
          </div>
        </Card>

        {!overLimit && (
          <FileUploadComponent
            generalApi={generalApi}
            files={files}
            setFiles={(newFiles) => setFiles(newFiles)}
          />
        )}

        <div className="justify-end lg:flex">
          <SolidButton
            className="px-8 lg:w-auto"
            isDisabled={licenseLoading || overLimit}
            isLoading={submitLoading}
            onClick={handleSubmit(saveData)}
          >
            CREAR NUEVO REGISTRO
          </SolidButton>
        </div>
      </form>
    </PrivateRoute>
  );
}
